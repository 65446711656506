import "../Components/styles/CheckoutForm.css";
import React, { Fragment, useState, useEffect } from "react";
import "../Components/styles/launcherPageComponent.css";
import "../Components/styles/confirmationPageComponent.css";
import { FooterSection } from "../Components/LauncherPageComponent/launcherPageComponent";
import { useNavigate } from "react-router-dom";
import { imagesLinks, socialLinks } from "../Constants/links";

const ConfirmationPageComponent = () => {
  const handleInstagramLink = () => {
    window.open(socialLinks.instagram, "_blank");
  };

  const handleFacebookLink = () => {
    window.open(socialLinks.facebook, "_blank");
  };

  const handleLinkdinLink = () => {
    window.open(
      socialLinks.linkedin,
      "_blank"
    );
  };

  const navigate = useNavigate();

  const handleMainMenuClick = () => {
    navigate("/minimis_launcher");
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 829);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 829);
    };

    // Initial check when the component mounts
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <div style={{ overflowX: "hidden" }}>
        <div className="d-flex confirmation-main-container">
          <div className="reservation_page confirmation-page">
            <div className="text_part confirmation-text-container">
              <div className="reservation_logo">
                <div>
                  <img src={imagesLinks.logo} className="navLogo" alt="" />
                </div>
              </div>
              <div className="checkout-container">
                <div className="scale-indicator-container">
                  <div className="label1">
                    <div className="dot1">
                      <div className="dotfil1"></div>
                    </div>
                    <div className="label2">
                      <p className="chp1">Early Access</p>
                    </div>
                  </div>
                  <div className="line1"></div>
                  <div className="label1">
                    <div className="dot1">
                      <div className="dotfil1"></div>
                    </div>
                    <div className="label2">
                      <p className="chp1">Checkout</p>
                    </div>
                  </div>
                  <div className="line1"></div>
                  <div className="label1">
                    <div className="dot2 active">
                      <div className="dotfil2"></div>
                    </div>
                    <div className="label2">
                      <p className="chp2 active">Confirmation</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text_box text-container">
                <p className="confirmation-page-heading">
                  Congrats, you’re a Minimis Lifer!
                </p>
                <p className="thanks-paragraph">
                  Thank you and welcome. Your contribution allows us to combat
                  the phone addiction epidemic and bring more people back into
                  the real world!
                </p>
                <p className="thanks-paragraph">
                  You should receive a welcome email to the email address you
                  paid with from Rose within the hour. If not, please check your
                  spam inbox.
                </p>
                <p className="thanks-paragraph">
                  If you can't find the email even in spam, please forward your
                  Stripe invoice to hey@minimis.life and we'll get you added
                  manually!
                </p>
                <div className="social-icons">
                  <img
                    src={imagesLinks.instagramIcon}
                    alt=""
                    onClick={handleInstagramLink}
                    style={{ width: "20px", cursor: "pointer" }}
                  />

                  <img
                    src={imagesLinks.linkedinIcon}
                    alt=""
                    onClick={handleLinkdinLink}
                    style={{ width: "20px", cursor: "pointer" }}
                  />
                  <img
                    src={imagesLinks.facebookIcon}
                    alt=""
                    onClick={handleFacebookLink}
                    style={{ width: "20px", cursor: "pointer" }}
                  />
                </div>
              </div>
              <button onClick={handleMainMenuClick} className="back-button">
                Back to main page
              </button>
              {isMobileView && (
                <div className="footer_section">
                  <FooterSection />
                </div>
              )}
            </div>

            <div className="cards_part image-display">
              <img src={imagesLinks.confirmationImage} alt="" />
            </div>
          </div>
        </div>
        {!isMobileView && (
          <div className="footer_section">
            <FooterSection />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ConfirmationPageComponent;
