import iphoneMockup from "../assests/launcher_page_img/iPhone-14-Pro-Mockup 1.png";
import googlePlaystoreImage from "../assests/launcher_page_img/google-playstore-image.png";
import androidMobileLauncherImage from "../assests/launcher_page_img/android-mobile-launcher-image.png";
import ratingReviewImage from "../assests/launcher_page_img/rating-review-image.png";
import minimisDownloadAppImage from "../assests/launcher_page_img/minimis-download-app-image.png";
import playStoreIcon from "../assests/launcher_page_img/play-store-icon.png";
import ratingStarImage from "../assests/launcher_page_img/rating-star-image.png";
import linkedinIcon from "../assests/launcher_page_img/linkedin-icon.png";
import facebookIcon from "../assests/launcher_page_img/facebook-icon.png";
import instagramIcon from "../assests/launcher_page_img/instagram-icon.png";
import confirmationImage from "../assests/launcher_page_img/iPhone-14-Pro-Mockup 1.png";
import logo from "../assests/images/MINIMIS (1).svg";
import c1 from "../assests/images/massive-lifetime-discount-icon.svg";
import c2 from "../assests/images/early-development-icon.svg";
import c3 from "../assests/images/payment-secured-icon.svg";
import visaIcon from "../assests/images/vis.png";
import mastercardIcon from "../assests/images/deb.png";
import amexIcon from "../assests/images/Amex.png";
import discoverIcon from "../assests/images/dis.png";
import defaultIcon from "../assests/images/defaultim.png";

export const socialLinks = {
    instagram: "https://www.instagram.com/minimistech/",
    facebook: "https://www.facebook.com/minimistech",
    linkedin: "https://www.linkedin.com/company/minimis-technologies",
};

export const imagesLinks = {
    iphoneMockup,
    googlePlaystoreImage,
    androidMobileLauncherImage,
    ratingReviewImage,
    minimisDownloadAppImage,
    playStoreIcon,
    ratingStarImage,
    linkedinIcon,
    facebookIcon,
    instagramIcon,
    confirmationImage,
    logo,
    c1,
    c2,
    c3,
    visaIcon,
    mastercardIcon,
    amexIcon,
    discoverIcon,
    defaultIcon,
    
}